<template>
  <win-block>
    <template #win-title-left>Настройки дома</template>
    <v-form>
      <v-text-field
          v-model="podezd"
          label="Подьездов"
          filled
      >
      </v-text-field>
      <v-text-field
          v-model="ent"
          label="Этажей"
          filled
      >
      </v-text-field>
      <v-text-field
          v-model="flats"
          label="Квартир"
          filled
      >
      </v-text-field>
    </v-form>
  </win-block>
</template>

<script>

export default {
  components: {
    "win-block": () => import('@/site/componets/winblock'),
  },
  data: () => ({
    ent:0,
    podezd:0,
    flats:0,
  }),
  methods: {
  }
}
</script>

